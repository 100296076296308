<template>
  <app-modal
    :title="$t('modal.address_edit')"
    modal-size="modal-md"
    @close="close"
  >
    <template slot="body">
      <div class="row">
        <div class="col-lg-12">
          <div v-if="regionsLoaded" class="form-group">
            <app-select
              v-model="region"
              :options="regions"
              id="address_region"
              :label="$t('address.region')"
              @change="regionChanged"
            >
            </app-select>
          </div>
          <div v-if="districtsLoaded && districts.length > 0" class="form-group">
            <app-select
              v-model="district"
              :options="districts"
              id="address_district"
              :label="$t('address.district')"
              @change="districtChanged"
            >
            </app-select>
          </div>
          <div v-if="displayCity" class="form-group">
            <div class="row">
              <div class="col-lg-5">
                <label>{{ $t('address.city') }}</label>
              </div>
              <div class="col-lg-7">
                <app-create-city-button
                  v-if="searchedCity"
                  :searchedCity="searchedCity"
                  :district="district"
                  @set-city="addCity"
                >
                </app-create-city-button>
              </div>
            </div>
            <app-multi-select
              v-model="city"
              :options="cities"
              label="title"
              track-by="id"
              :preselect-first="true"
              :loading="isLoadingCities"
              :internal-search="false"
              :options-limit="300"
              :limit="1"
              :max-height="600"
              :show-no-results="false"
              :multiple="false"
              :close-on-select="true"
              open-direction="bottom"
              @search-change="findCity"
              id="cities"
            >
            </app-multi-select>
          </div>
          <div v-if="displayStreet" class="form-group">
            <app-input
              v-model.trim="streetAndNumber"
              :label="$t('address.street_number')"
            >
            </app-input>
          </div>
        </div>
      </div>
    </template>
    <template slot="sub-header">
      <div class="row text-center">
        <div class="col-md-12" v-if="displaySubmit">
          <div class="input-group-btn">
            <button
              type="button"
              class="btn btn-success"
              @click="confirmModal"
            >
              {{ $t('address.confirm') }}
            </button>
          </div>
        </div>
      </div>
    </template>
  </app-modal>
</template>

<script>
import Input from '../form/inputs/Input'
import Modal from '../shared/Modal'
import MultiSelect from '../form/select/MultiSelect'
import Select from '../form/select/Select'
import CreateCityButton from './CreateCityButton'
import AddressModel from '../../model/AddressModel'
import NotifyService from '../../services/NotifyService'

const COUNTRY_DEFAULT_ID = 1

export default {
  name: 'AddressEditModal',
  props: {
    address: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      cities: [],
      city: null,
      region: null,
      district: null,
      streetAndNumber: '',
      country: COUNTRY_DEFAULT_ID,
      searchedCity: '',
      regionsLoaded: false,
      districtsLoaded: false,
      displayCity: false,
      isLoadingCities: false
    }
  },
  computed: {
    regions () {
      return this.$store.getters['region/list']
    },
    districts () {
      return this.$store.getters['district/list']
    },
    displaySubmit () {
      if (this.displayStreet && this.streetAndNumber.length > 0) {
        return true
      } else {
        return false
      }
    },
    displayStreet () {
      if (this.country > 0 && this.region > 0 && this.district > 0 && this.city &&
        typeof this.city.id !== 'undefined' && this.city.id > 0) {
        return true
      } else {
        return false
      }
    }
  },
  components: {
    appModal: Modal,
    appInput: Input,
    appMultiSelect: MultiSelect,
    appSelect: Select,
    appCreateCityButton: CreateCityButton
  },
  methods: {
    initData () {
      if (this.address !== null) {
        this.country = this.address.country
        this.fetchRegions()
        this.region = this.address.region
        this.fetchDistricts()
        this.district = this.address.district
        this.cities = [this.address.expanded.city]
        this.city = this.address.expanded.city
        this.displayCity = true
        this.streetAndNumber = this.address.street
      }
    },
    fetchRegions () {
      this.$store.dispatch('region/fetchAll', this.country)
        .then(() => {
          this.regionsLoaded = true
        })
    },
    fetchDistricts () {
      this.districtsLoaded = false
      this.$store.dispatch('district/fetchAll', this.region)
        .then(() => {
          this.districtsLoaded = true
        })
    },
    regionChanged () {
      this.district = null
      this.city = null
      this.cities = []
      this.streetAndNumber = ''
      this.fetchDistricts()
    },
    districtChanged () {
      this.city = null
      this.cities = []
      this.searchedCity = ''
      this.streetAndNumber = ''
      this.displayCity = true
    },
    close () {
      this.$emit('close')
    },
    findCity (query) {
      this.isLoadingCities = true
      this.searchedCity = ''
      const data = { title: query, district: this.district }
      this.$store.dispatch('city/fetchByTitleAndDistrict', data)
        .then(() => {
          this.cities = this.$store.getters['city/list']
          this.searchedCity = data.title
          this.isLoadingCities = false
        })
    },
    addCity (response) {
      this.cities = [response]
      this.city = response
    },
    confirmModal () {
      this.save()
    },
    prepareRequest () {
      const address = this._.cloneDeep(AddressModel)
      address.country = this.country
      address.region = this.region
      address.district = this.district
      address.city = this.city.id
      address.street = this.streetAndNumber
      return address
    },
    async save () {
      this.$store.dispatch('address/create', this.prepareRequest())
        .then(() => {
          if (this.$store.getters['address/error'] === null) {
            this.$emit('set-address', this.$store.getters['address/detail'])
            this.close()
          } else {
            NotifyService.setErrorMessage(this.$store.getters['restaurant/error'])
          }
        })
        .catch(error => console.log(error))
    }
  },
  mounted () {
    this.initData()
    this.fetchRegions()
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
