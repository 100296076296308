<template>
  <div :class="{'form-group': !disableFormGroup, 'has-danger': error}">
    <label :for="id" v-if="!noLabel">
      {{ label }} <span v-if="required" class="required">*</span>
      <app-tooltip v-if="tooltip.length" :title="tooltip"></app-tooltip>
    </label>

    <div class="input-group">
      <input
        :type="type"
        :id="id"
        class="form-control"
        @input="$emit('input', validate($event.target.value))"
        @blur="$emit('blur', validate($event.target.value))"
        :value="value"
        :disabled="disabled"
        :placeholder="placeholder"
        :maxlength="maxlength"
        :readonly="readonly"
        :max="max"
        :min="min"
        :step="step"
        :data-test="dataAttr ? dataAttr : id"
      >
      <div class="input-group-append" v-if="showButtons && firstActionsOrButtons === 'buttons'">
        <button v-for="(button, buttonIndex) in buttons" :key="`item-${buttonIndex}`" :class="button.class" type="button"
                v-html="button.html" @click="buttonClick(buttonIndex)"></button>
      </div>
      <div class="input-group-append" v-if="showActions">
        <button :class="actionsButtonClass" type="button" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">{{ actionsButtonText }}
        </button>
        <div class="dropdown-menu">
          <a href="javascript:void(0)" v-for="(action, actionIndex) in actions" class="dropdown-item"
             :key="`item-${actionIndex}`"
             @click="actionClick(actionIndex)">
            {{ action }}
          </a>
        </div>
      </div>
      <div class="input-group-append" v-if="showButtons && firstActionsOrButtons === 'actions'">
        <button v-for="(button, buttonIndex) in buttons" :key="`item-${buttonIndex}`" :class="button.class" type="button"
                v-html="button.html" @click="buttonClick(buttonIndex)"></button>
      </div>
    </div>
  </div>
</template>

<script>
import Tooltip from '../../tooltip/Tooltip'

export default {
  name: 'InputAdvanced',
  props: {
    id: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    noLabel: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text'
    },
    tooltip: {
      type: String,
      default: ''
    },
    error: {
      type: Boolean,
      default: false
    },
    showButtons: {
      type: Boolean,
      default: false
    },
    showActions: {
      type: Boolean,
      default: false
    },
    firstActionsOrButtons: {
      type: String,
      default: 'buttons'
    },
    buttons: {
      type: Array,
      default: () => [{ class: 'btn', html: 'Button' }]
    },
    buttonHtml: {
      type: String,
      default: ''
    },
    actionsButtonText: {
      type: String,
      default: ''
    },
    actionsButtonClass: {
      type: String,
      default: 'btn dropdown-toggle'
    },
    actions: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    disableFormGroup: {
      type: Boolean,
      default: false
    },
    value: {},
    placeholder: {
      type: String,
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    maxlength: {
      type: Number,
      default: 255
    },
    max: {
      type: Number,
      default: null
    },
    min: {
      type: Number,
      default: null
    },
    step: {
      type: Number,
      default: 1
    },
    dataAttr: {
      type: String,
      required: false,
      default: ''
    }
  },
  components: {
    appTooltip: Tooltip
  },
  methods: {
    validate (value) {
      if (this.max) {
        return this.validateMax(value)
      }
      return value
    },
    validateMax (value) {
      if (value > this.max) {
        return this.max
      }
      return value
    },
    buttonClick (index) {
      this.$emit('input-button-clicked', index)
    },
    actionClick (index) {
      this.$emit('input-action-clicked', index)
    }
  }
}
</script>

<style lang="scss" scoped>
  .input-group-text {
    width: 50px;
    font-size: 12px;
    text-align: center;
  }

  .has-danger {
    .input-group-text {
      color: #fc4b6c;
    }
  }
</style>
