<template>
    <span>
        <button
          type="button"
          v-if="showUsageButton"
          class="btn btn-secondary"
          :title="$t('buttons.usage_button')"
          @click="showModal(false)"
        >
            <i class="fas fa-project-diagram"></i> <span class="hidden-sm-down">{{ $t('buttons.usage_button') }}</span>
        </button>
        <button
          type="button"
          v-if="showDeleteButton && hasPermission"
          class="btn btn-danger btn-delete-row"
          :title="$t('buttons.delete_button')"
          @click="showModal(true)"
        >
            <i class="fa fa-trash"></i> <span class="hidden-sm-down">{{ $t('buttons.delete_button') }}</span>
        </button>

        <app-modal
          v-if="modal"
          :modal-size="modalSize"
          :title="$t('modal.usage_modal_header')"
          @close="closeModal"
        >
            <!-- Body -->
            <template slot="body">
                <div v-if="showLoader">
                    <app-preloader></app-preloader>
                </div>
                <div v-else class="text-left">
                    <p v-if="totalCount > 0 && showDelete" class="alert alert-warning">
                        {{ $t('modal.usage_modal_description') }}
                    </p>
                    <app-data-table
                      v-if="!showDelete || totalCount > 0"
                      :data="data"
                      :totalCount="totalCount"
                      :page="page"
                      :config="config"
                      actions-in-blank-window
                      @page-change="setPageAndGetRecords"
                    >
                    </app-data-table>
                    <p v-if="showDeleteButtons">{{ $t('modal.delete_modal_text') }}</p>
                </div>
            </template>

          <!-- Footer -->
            <template slot="footer">
                <template v-if="showDeleteButtons && !showLoader">
                    <button
                      type="button"
                      class="btn btn-inverse router-link-active"
                      @click="closeModal"
                    >
                        {{ $t('modal.delete_modal_cancel_btn') }}
                    </button>
                    <button
                      type="button"
                      class="btn btn-danger"
                      @click="deleteRecord"
                    >
                        {{ $t('modal.delete_modal_confirm_btn') }}
                    </button>
                </template>
                <template v-else>
                    <button
                      type="button"
                      class="btn btn-inverse router-link-active"
                      @click="closeModal"
                    >
                        {{ $t('buttons.close') }}
                    </button>
                </template>
            </template>
        </app-modal>
    </span>
</template>

<script>
import Modal from '../shared/Modal'
import DataTable from '../shared/DataTable'
import Preloader from '../preloader/Preloader'

export default {
  name: 'ButtonDelete',
  props: {
    showDeleteButton: {
      type: Boolean,
      default: true
    },
    showUsageButton: {
      type: Boolean,
      default: true
    },
    data: {
      type: Array,
      default: () => []
    },
    totalCount: {
      type: Number,
      default: 0
    },
    page: {
      type: Number,
      default: 1
    },
    showLoader: {
      type: Boolean,
      default: false
    },
    config: {
      type: Object
    },
    requiredPermissions: {
      type: Array,
      default: () => []
    },
    siteIdForPermissionCheck: {
      type: Number,
      required: false
    }
  },
  data () {
    return {
      modal: false,
      showDelete: false
    }
  },
  components: {
    appModal: Modal,
    appDataTable: DataTable,
    appPreloader: Preloader
  },
  methods: {
    showModal (showDelete) {
      this.$emit('load-data')
      this.showDelete = showDelete
      this.modal = true
    },
    closeModal () {
      this.modal = false
    },
    deleteRecord () {
      this.$emit('deleteRecord')
    },
    setPageAndGetRecords () {
      this.$emit('page-change', this.currentPage)
    }
  },
  computed: {
    hasPermission () {
      if (this.siteIdForPermissionCheck) {
        return this.$store.getters['user/hasPermissionForSite'](this.requiredPermissions, this.siteIdForPermissionCheck)
      }
      return this.$store.getters['user/hasPermission'](this.requiredPermissions)
    },
    modalSize () {
      if (this.showDeleteButtons) {
        return 'modal-md'
      }
      return 'modal-lg'
    },
    showDeleteButtons () {
      return this.showDelete && this.totalCount === 0
    }
  }
}
</script>
