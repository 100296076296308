<template>
  <div style="text-align: right">
    <button
      class="btn btn-xs btn-info m-b-5"
      :title="$t('address.create_new_city')"
      v-if="searchedCity !== ''"
      @click="createCity"
    >
      <i class="fa fa-plus"></i>
      {{ $t('address.create_new_city') }} <strong>{{ searchedCity }}</strong>
    </button>
  </div>
</template>

<script>
import CoreApi from '../../api/core'
import NotifyService from '../../services/NotifyService'

export default {
  name: 'CreateCityButton',
  props: {
    searchedCity: {
      type: String,
      default: ''
    },
    district: {
      type: Number,
      default: null
    }
  },
  methods: {
    createCity () {
      CoreApi().post('/city', { title: this.searchedCity, district: this.district })
        .then((response) => {
          NotifyService.setSuccessMessage(this.$t('address.create_new_city_message'))
          this.$emit('set-city', response.data)
        })
        .catch(error => {
          console.error(error)
        })
    }
  }
}
</script>
