export default {
  computed: {
    restaurantHotelRatingValues () {
      return [
        {
          value: 0,
          title: '-'
        },
        {
          value: 10,
          title: '0.5 ★'
        },
        {
          value: 20,
          title: '1.0 ★'
        },
        {
          value: 30,
          title: '1.5 ★'
        },
        {
          value: 40,
          title: '2.0 ★'
        },
        {
          value: 50,
          title: '2.5 ★'
        },
        {
          value: 60,
          title: '3.0 ★'
        },
        {
          value: 70,
          title: '3.5 ★'
        },
        {
          value: 80,
          title: '4.0 ★'
        },
        {
          value: 90,
          title: '4.5 ★'
        },
        {
          value: 100,
          title: '5.0 ★'
        }
      ]
    }
  }
}
