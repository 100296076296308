export default {
  computed: {
    hotelStarsValues () {
      return [
        {
          value: 0,
          title: '-'
        },
        {
          value: 1,
          title: '★'
        },
        {
          value: 2,
          title: '★★'
        },
        {
          value: 3,
          title: '★★★'
        },
        {
          value: 4,
          title: '★★★★'
        },
        {
          value: 5,
          title: '★★★★★'
        }
      ]
    }
  }
}
