<template>
  <div v-if="dataLoaded">
    <div v-if="this.address">
      {{ this.address.street }}<br>
      {{ this.address.expanded.city.title }}<br>
      {{ this.address.expanded.district.title }}<br>
      {{ this.address.expanded.country.title }}<br>
    </div>
    <span>
            <button
              v-if="showEdit"
              class="btn btn-info"
              @click="showAddressModal"
            >
                <i class="fas fa-address-book"></i>
                {{ $t('buttons.set_address') }}
            </button>
            <button
              v-if="showDelete && this.address && this.address.id > 0"
              class="btn btn-danger btn-delete-row"
              @click="resetAddress"
            >
                <i class="fa fa-trash"></i>
                {{ $t('buttons.reset_address') }}
            </button>
            <app-address-edit-modal
              v-if="addressModal"
              :address="address"
              @set-address="setAddress"
              @close="closeAddressModal"
            >
            </app-address-edit-modal>
        </span>
  </div>
</template>

<script>
import AddressEditModal from '../address/AddressEditModal'

export default {
  name: 'AddressButton',
  props: {
    addressId: {
      type: Number,
      default: null
    },
    showEdit: {
      type: Boolean,
      default: true
    },
    showDelete: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      addressModal: false,
      address: null,
      dataLoaded: false
    }
  },
  components: {
    appAddressEditModal: AddressEditModal
  },
  methods: {
    resetAddress () {
      this.$emit('delete-address', this.addressId)
      this.address = null
    },
    fetchAddress () {
      if (this.addressId === null) {
        this.dataLoaded = true
      } else {
        this.$store.dispatch('address/fetchOne', this.addressId)
          .then(() => {
            this.address = this.$store.getters['address/detail']
            this.dataLoaded = true
          })
      }
    },
    showAddressModal () {
      this.addressModal = true
    },
    closeAddressModal () {
      this.addressModal = false
    },
    setAddress (address) {
      this.address = address
      this.$emit('set-address', address)
    }
  },
  mounted () {
    this.fetchAddress()
  }
}
</script>

<style lang="scss">
</style>
